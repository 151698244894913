// import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
// import { Upload } from "@aws-sdk/lib-storage";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUrl } from "../../helpers/getUrl";
import { decryptData } from "../../utils/DecryptLocalStorage";
import { uploadToS3 } from "./UploadToS3";

const storage = localStorage.getItem("alpha") ? localStorage.getItem("alpha") : "{}"
const data = decryptData(storage, process.env.REACT_APP_SKEY);
const getToken = data && data.token;

export const uploadApi = createApi({
  reducerPath: 'uploadApi',
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  endpoints: (builder) => ({
    uploadFrontViewImage: builder.mutation({
      async queryFn(file) {
        return uploadToS3(file);
      },
    }),
    uploadLeftViewImage: builder.mutation({
      async queryFn(file) {
        return uploadToS3(file);
      },
    }),
    uploadRightViewImage: builder.mutation({
      async queryFn(file) {
        return uploadToS3(file);
      },
    }),
    uploadRearViewImage: builder.mutation({
      async queryFn(file) {
        return uploadToS3(file);
      },
    }),
    uploadVideo: builder.mutation({
      async queryFn(file) {
        return uploadToS3(file);
      },
    }),
  }),
});


export const getGIGLocationApi = createApi({
  reducerPath: "getGIGLocationApi",
  baseQuery: fetchBaseQuery({baseUrl: `${getUrl(process.env.REACT_APP_ORDER_BASE_URL_PRODUCTION,process.env.REACT_APP_ORDER_BASE_URL)}`, prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token || getToken;

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
  
      return headers
    }}),
  endpoints: (builder) => ({
      getActiveLocations: builder.query({
          query: () => ({
              url: `/order/getactive_location`,
              method: "get"
          })
      })
  })
});


export const getMerchantStoreApi = createApi({
reducerPath: "getMerchantStoreApi",
baseQuery: fetchBaseQuery({
  baseUrl: `${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}`,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token || getToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  }
}),
endpoints: (builder) => ({
  getMerchantStore: builder.query({
    query: owner_id => ({
      url: `/store/customer_store?owner=${owner_id}`,
      method: "get"
    })
  }),    
  bulkProductUpload: builder.mutation({
    query: ({body}) => ({
      url: '/product/batch/upload',
      method: 'post',
      body,
    })
  })
})
});

// export const uploadApi = createApi({
//   reducerPath: 'uploadApi',
//   baseQuery: fetchBaseQuery({
//     baseUrl: `${getUrl(process.env.REACT_APP_PRODUCT_BASE_URL_PRODUCTION, process.env.REACT_APP_PRODUCT_BASE_URL)}`,
//     prepareHeaders: (headers, { getState }) => {
//       const token = getState().auth.token || getToken;
//       if (token) {
//         headers.set('authorization', `Bearer ${token}`);
//       }
//       return headers;
//     }
//   }),
//   endpoints: (builder) => ({
//     uploadFrontViewImage: builder.mutation({
//       query: ({ imageFile }) => {
//         let bodyFormData = new FormData();
//         bodyFormData.append('front_view', imageFile);
//         return {
//           url: '/product/photo_upload',
//           method: 'POST',
//           body: bodyFormData,
//         };
//       },
//     }),
//     uploadLeftViewImage: builder.mutation({
//       query: ({ imageFile }) => {
//         let bodyFormData = new FormData();
//         bodyFormData.append('left_view', imageFile);
//         return {
//           url: '/product/photo_upload',
//           method: 'POST',
//           body: bodyFormData,
//         };
//       },
//     }),
//     uploadRightViewImage: builder.mutation({
//       query: ({ imageFile }) => {
//         let bodyFormData = new FormData();
//         bodyFormData.append('right_view', imageFile);
//         return {
//           url: '/product/photo_upload',
//           method: 'POST',
//           body: bodyFormData,
//         };
//       },
//     }),
//     uploadRearViewImage: builder.mutation({
//       query: ({ imageFile }) => {
//         let bodyFormData = new FormData();
//         bodyFormData.append('rear_view', imageFile);
//         return {
//           url: '/product/photo_upload',
//           method: 'POST',
//           body: bodyFormData,
//         };
//       },
//     }),
//     uploadVideo: builder.mutation({
//       query: ({ videoFile }) => {
//         let bodyFormData = new FormData();
//         bodyFormData.append('video_url', videoFile);
//         return {
//           url: '/product/video/upload',
//           method: 'POST',
//           body: bodyFormData,
//         };
//       },
//     }),
//   }),
// });

export const listPromotionsApi = createApi({
  reducerPath: "listPromotionsApi",
  baseQuery: fetchBaseQuery({baseUrl: `${getUrl(process.env.REACT_APP_USER_BASE_URL_PRODUCTION,process.env.REACT_APP_USER_BASE_URL)}`, prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token || getToken;
  
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
  
      return headers
    }}),
  endpoints: (builder) => ({
      listPromtions: builder.query({
          query: () => ({
              url: `/setting/promotion/list`,
              method: "get"
          })
      })
  })
});

export const { useGetMerchantStoreQuery, useBulkProductUploadMutation } = getMerchantStoreApi;
export const { useUploadFrontViewImageMutation, useUploadVideoMutation,useUploadLeftViewImageMutation, useUploadRightViewImageMutation, useUploadRearViewImageMutation} = uploadApi;
export const {useGetActiveLocationsQuery} = getGIGLocationApi;
export const {useListPromtionsQuery} = listPromotionsApi