import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import { BsArrowLeft } from "react-icons/bs";
import PropagateLoader from 'react-spinners/PropagateLoader';
import BulkProductCard from './BulkProductCard';
import { AppContext } from '../../../../AppContexts/AppContext';
import { actionTypes } from '../../../../AppContexts/constants';
import { useBulkProductUploadMutation } from '../../../../Actions/BulkUpload/BulkUpload';
import { toast } from 'react-toastify';
import { useLocalStorage } from '../../../../utils/useLocalStorage';
import { DisplayContext } from './../../../../AppContexts/DisplayContext';

import "./bulk.css";

const override = {
  margin: "0",
  borderColor: "none",
  height: "50px",
  width: "100%",
  alignItems: "center",
  justifyContent: "center"
};

const BulkProducts = () => {
  const [isHovered, setIsHovered] = useState(false);
  const {getItem, removeItem} = useLocalStorage("batch_upload_items")
  const [bulkProductUpload, {isError, error, isSuccess, data, isLoading}] = useBulkProductUploadMutation()
  const {uploadDispatch, state, setUploadSuccessData, setEditIndex, setPriceValue} = useContext(AppContext);
  const {setCheckPickup} = useContext(DisplayContext)
  const itemsLength = getItem().length;
  const uploadData = (state && state.batch_upload_items) || getItem();
  const navigate = useNavigate();


  const body = uploadData.map(({sub_category_sizes,state,prefilledData,success_data, get_category, batch_upload_items, lga, ...rest}) => rest);

  
  // const testData = [
  //   {
  //       "tag": "new",
  //       "vehicleType": "BIKE",
  //       "address": "2 Sunday Ogunyade St, Abule Okuta, Lagos 102216, Lagos, Nigeria",
  //       "productName": "test bag",
  //       "color": "",
  //       "front_view": "https://alphaobjectcontainernew.s3.amazonaws.com/byZJsM83p-bags.webp",
  //       "description": "test bag",
  //       "rear_view": "",
  //       "left_view": "",
  //       "right_view": "",
  //       "video_url": "",
  //       "category": "625df6dc6b928316485a542f",
  //       "sub_category": "Grocery",
  //       "price": 50,
  //       "quantity": 1,
  //       "customer_code": "IND265936",
  //       "promo_name": "",
  //       "discounted_price": 0,
  //       "lat": 6.5482971,
  //       "long": 3.3887508,
  //       "sizes": [],
  //       "gender": "",
  //       "productImage": "",
  //       "userId": "62a82a340ac02f088ceaeeec",
  //       "gift_wrapping": false,
  //       "merchant_first_name": "IT",
  //       "merchant_last_name": "User",
  //       "merchant_phone_number": "+2347063965528",
  //       "merchant_email": "finance@thegiggroupng.com",
  //       "neighborhood": "Abule Okuta",
  //       "locality": "Lagos",
  //       "merchant_station_id": 4,
  //       "weight": "1"
  //   },
  //   {
  //       "tag": "new",
  //       "vehicleType": "BIKE",
  //       "address": "2 Sunday Ogunyade St, Abule Okuta, Lagos 102216, Lagos, Nigeria",
  //       "productName": "cypher test",
  //       "color": "",
  //       "front_view": "https://alphaobjectcontainernew.s3.eu-west-2.amazonaws.com/yXMHKYZDF-cloth.jpg",
  //       "description": "cypher short",
  //       "rear_view": "",
  //       "left_view": "",
  //       "right_view": "",
  //       "video_url": "",
  //       "category": "625df6dc6b928316485a542f",
  //       "sub_category": "Grocery",
  //       "price": 50,
  //       "quantity": 1,
  //       "customer_code": "IND265936",
  //       "promo_name": "",
  //       "discounted_price": 0,
  //       "lat": 6.5482971,
  //       "long": 3.3887508,
  //       "sizes": [],
  //       "gender": "",
  //       "productImage": "",
  //       "userId": "62a82a340ac02f088ceaeeec",
  //       "gift_wrapping": false,
  //       "merchant_first_name": "IT",
  //       "merchant_last_name": "User",
  //       "merchant_phone_number": "+2347063965528",
  //       "merchant_email": "finance@thegiggroupng.com",
  //       "neighborhood": "Abule Okuta",
  //       "locality": "Lagos",
  //       "merchant_station_id": 4,
  //       "weight": "1"
  //   },
  //   {
  //       "tag": "new",
  //       "vehicleType": "BIKE",
  //       "address": "2 Sunday Ogunyade St, Abule Okuta, Lagos 102216, Lagos, Nigeria",
  //       "productName": "test cypher",
  //       "color": "",
  //       "front_view": "https://alphaobjectcontainernew.s3.eu-west-2.amazonaws.com/oLggED9Cs-kora.png",
  //       "description": "test cypher",
  //       "rear_view": "",
  //       "left_view": "",
  //       "right_view": "",
  //       "video_url": "",
  //       "category": "625df6dc6b928316485a542f",
  //       "sub_category": "Grocery",
  //       "price": 50,
  //       "quantity": 1,
  //       "customer_code": "IND265936",
  //       "promo_name": "",
  //       "discounted_price": 0,
  //       "lat": 6.5482971,
  //       "long": 3.3887508,
  //       "sizes": [],
  //       "gender": "",
  //       "productImage": "",
  //       "userId": "62a82a340ac02f088ceaeeec",
  //       "gift_wrapping": false,
  //       "merchant_first_name": "IT",
  //       "merchant_last_name": "User",
  //       "merchant_phone_number": "+2347063965528",
  //       "merchant_email": "finance@thegiggroupng.com",
  //       "neighborhood": "Abule Okuta",
  //       "locality": "Lagos",
  //       "merchant_station_id": 4,
  //       "weight": "0"
  //   },
  //   {
  //       "tag": "new",
  //       "vehicleType": "BIKE",
  //       "address": "1 Sunday Ogunyade St, Gbagada, Lagos 100234, Lagos, Nigeria",
  //       "productName": "cypher cufflinks",
  //       "color": "",
  //       "front_view": "https://alphaobjectcontainernew.s3.eu-west-2.amazonaws.com/taKVdNpnH-bags.webp",
  //       "description": "cypher cufflinks",
  //       "rear_view": "",
  //       "left_view": "",
  //       "right_view": "",
  //       "video_url": "",
  //       "category": "625df6dc6b928316485a542f",
  //       "sub_category": "Grocery",
  //       "price": 50,
  //       "quantity": 1,
  //       "customer_code": "IND265936",
  //       "promo_name": "",
  //       "discounted_price": 0,
  //       "lat": 6.5483641,
  //       "long": 3.3891185,
  //       "sizes": [],
  //       "gender": "",
  //       "productImage": "",
  //       "userId": "62a82a340ac02f088ceaeeec",
  //       "gift_wrapping": false,
  //       "merchant_first_name": "IT",
  //       "merchant_last_name": "User",
  //       "merchant_phone_number": "+2347063965528",
  //       "merchant_email": "finance@thegiggroupng.com",
  //       "neighborhood": "Gbagada",
  //       "locality": "Lagos",
  //       "merchant_station_id": 4,
  //       "weight": "0.5"
  //   },
  //   {
  //       "tag": "new",
  //       "vehicleType": "BIKE",
  //       "address": "1 Sunday Ogunyade St, Gbagada, Lagos 100234, Lagos, Nigeria",
  //       "productName": "cypher belt",
  //       "color": "",
  //       "front_view": "https://alphaobjectcontainernew.s3.eu-west-2.amazonaws.com/sx_fsc54l-cloth.jpg",
  //       "description": "cypher belt",
  //       "rear_view": "",
  //       "left_view": "",
  //       "right_view": "",
  //       "video_url": "",
  //       "category": "625df6dc6b928316485a542f",
  //       "sub_category": "Grocery",
  //       "price": 1,
  //       "quantity": 1,
  //       "customer_code": "IND265936",
  //       "promo_name": "",
  //       "discounted_price": 0,
  //       "lat": 6.5483641,
  //       "long": 3.3891185,
  //       "sizes": [],
  //       "gender": "",
  //       "productImage": "",
  //       "userId": "62a82a340ac02f088ceaeeec",
  //       "gift_wrapping": false,
  //       "merchant_first_name": "IT",
  //       "merchant_last_name": "User",
  //       "merchant_phone_number": "+2347063965528",
  //       "merchant_email": "finance@thegiggroupng.com",
  //       "neighborhood": "Gbagada",
  //       "locality": "Lagos",
  //       "merchant_station_id": 4,
  //       "weight": "0.5"
  //   },
  //   {
  //       "tag": "",
  //       "vehicleType": "BIKE",
  //       "address": "1 Sunday Ogunyade St, Gbagada, Lagos 100234, Lagos, Nigeria",
  //       "productName": "cypher beverage",
  //       "color": "",
  //       "front_view": "https://alphaobjectcontainernew.s3.eu-west-2.amazonaws.com/gVWEfrPKi-cloth.jpg",
  //       "description": "cypher beverage",
  //       "rear_view": "",
  //       "left_view": "https://alphaobjectcontainernew.s3.eu-west-2.amazonaws.com/2dsuQl7f7-bags.webp",
  //       "right_view": "",
  //       "video_url": "",
  //       "category": "625df6dc6b928316485a542f",
  //       "sub_category": "Grocery",
  //       "price": 100,
  //       "quantity": 1,
  //       "customer_code": "IND265936",
  //       "promo_name": "",
  //       "discounted_price": 0,
  //       "lat": 6.5483641,
  //       "long": 3.3891185,
  //       "sizes": [],
  //       "gender": "",
  //       "productImage": "",
  //       "userId": "62a82a340ac02f088ceaeeec",
  //       "gift_wrapping": false,
  //       "merchant_first_name": "IT",
  //       "merchant_last_name": "User",
  //       "merchant_phone_number": "+2347063965528",
  //       "merchant_email": "finance@thegiggroupng.com",
  //       "neighborhood": "Gbagada",
  //       "locality": "Lagos",
  //       "merchant_station_id": 4,
  //       "weight": "1"
  //   },
  //   {
  //       "tag": "new",
  //       "vehicleType": "BIKE",
  //       "address": "1 Sunday Ogunyade St, Gbagada, Lagos 100234, Lagos, Nigeria",
  //       "productName": "grocery cypher",
  //       "color": "",
  //       "front_view": "https://alphaobjectcontainernew.s3.eu-west-2.amazonaws.com/dLDL93Rx0-bags.webp",
  //       "description": "grocery cypher",
  //       "rear_view": "",
  //       "left_view": "",
  //       "right_view": "",
  //       "video_url": "",
  //       "category": "625df6dc6b928316485a542f",
  //       "sub_category": "Grocery",
  //       "price": 70,
  //       "quantity": 1,
  //       "customer_code": "IND265936",
  //       "promo_name": "",
  //       "discounted_price": 20,
  //       "lat": 6.5483641,
  //       "long": 3.3891185,
  //       "sizes": [],
  //       "gender": "",
  //       "productImage": "",
  //       "userId": "62a82a340ac02f088ceaeeec",
  //       "gift_wrapping": false,
  //       "merchant_first_name": "IT",
  //       "merchant_last_name": "User",
  //       "merchant_phone_number": "+2347063965528",
  //       "merchant_email": "finance@thegiggroupng.com",
  //       "neighborhood": "Gbagada",
  //       "locality": "Lagos",
  //       "merchant_station_id": 4,
  //       "weight": "1"
  //   }
  // ]

  // const testBody = testData.map(({sub_category_sizes,state,prefilledData,success_data, get_category, batch_upload_items, lga, ...rest}) => rest);


  const handleBulkUpload = async () => {
      try {
        await bulkProductUpload({body});
      } catch (error) {
        toast.error(`Upload failed ${error}`)
      }
  };

  const clearState = () => {
       uploadDispatch({type: actionTypes.CLEAR_STATE});
       navigate("/merchant/store/upload");
       setCheckPickup("");
       setEditIndex(null);
       setPriceValue("");
  }

  useEffect(() => {
    if(isError) {
      const errorMessage = error?.data ? error.data.message : error?.error;
      toast.error(`${errorMessage}`);
    }
  }, [isError, error?.error, error?.data]);

  useEffect(() => {
     if(isSuccess){
          setUploadSuccessData(data?.results)
          navigate("/merchant/store/upload/success");
          removeItem()
     }
  }, [isSuccess, setUploadSuccessData, removeItem, data?.results, navigate]);

  const backTo = () => {
      navigate(-1);
      uploadDispatch({type: actionTypes.CLEAR_STATE});
      setPriceValue("")
      setCheckPickup("")
      setEditIndex(null)
  }

  return (
    <article className="upload-section">
        <div 
          style={getBtnStyle(isHovered)} 
          onClick={backTo}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <BsArrowLeft size={20}/> Back
        </div>
        <div className="upload-header bulk-upload-header">
        
            <div>
                <div>Upload Product</div>
                <div><div className="green-dot"/> Bulk upload {itemsLength ? `(${itemsLength})` : "0"}</div>
            </div>
            <div className='bulk-actions'>
                <div className='clear-btn' onClick={clearState}>
                      <div className='add-new-plus'>
                        <Plus size={14}/>
                      </div>
                      <span>Add new product</span>
                </div>
              <button className='btn__submit-load' disabled={itemsLength === 0} onClick={handleBulkUpload} type="button">{isLoading ? (  <div className="loader-container">
                  <PropagateLoader  color={"#FFFFFF"} loading={isLoading} cssOverride={override} />
      </div>) : "Finish Upload"}</button>
            </div>
        </div>
        {
          itemsLength === 0 ? <h5 className='text-center py-4'>
              No item to upload
          </h5> : <BulkProductCard />
        }
   </article>
  )
}

export default BulkProducts;

const getBtnStyle = (isHovered) => ({
  display: "flex",
  alignItems: "center", 
  gap: "5px", 
  marginBottom: "10px",
  fontSize: "14px",
  fontWeight: 600,
  cursor: "pointer",
  color: isHovered ? "#192150" : "rgba(0, 0, 0, 0.6)",
  transition: "color 0.3s ease",
});